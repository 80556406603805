var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return _c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Agregar Producto")])],1),_c('v-card-text',[_c('v-form',[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Nombre","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Tipo Tours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"items":_vm.typeTours,"item-text":"shift","item-value":"idTour","error-messages":errors,"success":valid,"label":"Tipo Tours","return-object":"","required":""},model:{value:(_vm.form.idTypeTours),callback:function ($$v) {_vm.$set(_vm.form, "idTypeTours", $$v)},expression:"form.idTypeTours"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"monto","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Precio Referencial","value":"10.00","prefix":"S/.","required":""},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableRegister,"loading":_vm.loadingRegister},on:{"click":_vm.submit}},[_vm._v("Registrar")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"yellow","disabled":_vm.disableUpdate,"loading":_vm.loadingUpdate,"dark":""},on:{"click":_vm.update}},[_vm._v("Actualizar")])],1)],1)}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"multi-sort":"","loading":_vm.loadingCollaborators,"search":_vm.search,"headers":_vm.headers,"items":_vm.lista,"items-per-page":20},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.editDeleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editDeleteItem(Object.assign({}, item, {isDelete: true}))}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }